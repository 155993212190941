import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const CtaBanner = ({
	heading,
	btnCta1,
	btnCta2,
	bgImage,
	meetHeading,
	meetContent,
	memberImage,
}) => {
	return (
		<section
			className="py-5 py-md-8"
			style={sectionWrapper(bgImage?.sourceUrl)}
		>
			<Container>
				<Row className={`align-items-lg-center justify-content-lg-center`}>
					<Col lg={12} className={`mb-4 mb-lg-0 text-center`}>
						<h2
							className="my-0 mx-4 text-white heading fs-2 inter-regular"
							dangerouslySetInnerHTML={{ __html: heading }}
						/>

						<div
							className={`d-flex align-items-lg-center flex-column flex-md-row justify-content-center mt-4`}
						>
							{btnCta1 && btnCta1.url && (
								<Button
									as={Link}
									href={btnCta1.url}
									size="lg"
									className="px-4 py-2 me-md-4 mb-4 mb-md-0 inter-medium btn-main"
									target={btnCta1.target}
									variant="secondary"
								>
									{btnCta1.title}
								</Button>
							)}

							{btnCta2 && btnCta2.url && (
								<Button
									as={Link}
									href={btnCta2.url}
									size="lg"
									className="px-4 py-2 inter-medium border-white btn-transparent"
									target={btnCta2.target}
								>
									{btnCta2.title}
								</Button>
							)}
						</div>
						<Row className="mt-6 bg-white p-3 p-md-5 mx-0 rounded-4 align-items-lg-center">
							<Col lg={3} className="text-center text-lg-start">
								<GatsbyImage
									image={memberImage.localFile.childImageSharp.gatsbyImageData}
									style={{ width: "260px", height: "260px" }}
									className="rounded-circle object-fit-cover mb-3 mb-md-4 mb-lg-0"
									alt={memberImage.altText}
								/>
							</Col>
							<Col lg={9} className="text-start ps-lg-6">
								<h2
									className="my-0 text-primary fs-2 inter-regular"
									dangerouslySetInnerHTML={{ __html: meetHeading }}
								/>
								<div
									className="my-0 text-dark-grey mt-3 meet-content"
									dangerouslySetInnerHTML={{ __html: meetContent }}
								>
									{heading}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export const sectionWrapper = (imgUrl) => ({
	background: `linear-gradient(0deg, rgba(29, 18, 63, 0.61) 0%, rgba(29, 18, 63, 0.61) 100%), url(${imgUrl}) lightgray 50% / cover no-repeat`,
});

export default CtaBanner;
