import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";
import { BgImage } from "gbimage-bridge";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const HeroBanner = ({
	heading,
	subHeading,
	description,
	webinarDescription,
	btnCta1,
	btnCta2,
	bgImage,
	memberImage,
	isDefault = true,
}) => {
	const textColor = () => {
		return isDefault ? "text-white" : "text-light-black";
	};

	const subHeadingColor = () => {
		return isDefault ? "text-white" : "text-dark-grey";
	};

	return (
		<section
			className={`d-flex ${!isDefault ? "my-9" : ""}`}
			style={bgImage && bannerWrapper(bgImage)}
		>
			<Container>
				<Row
					className={`flex-column-reverse ${
						isDefault
							? "justify-content-between flex-lg-row"
							: "justify-content-center flex-lg-row-reverse"
					}`}
				>
					<Col
						lg={isDefault ? 8 : 7}
						className={`mb-4 mb-lg-0 ${isDefault ? "pe-lg-9" : "ps-lg-9"}`}
					>
						<span className={`fs-5 ${subHeadingColor()}`}>{subHeading}</span>
						<h1
							className={`my-auto ${textColor()} heading mt-2 lh-sm ${
								isDefault ? "inter-bold" : "inter-regular"
							}`}
							dangerouslySetInnerHTML={{ __html: heading }}
						/>
						{description && (
							<div
								className="text-white pt-3 mb-0"
								dangerouslySetInnerHTML={{ __html: description }}
							></div>
						)}

						<div
							className={`${textColor()} mb-0 mt-4 border border-dark-blue p-4 webinar-desc`}
							dangerouslySetInnerHTML={{ __html: webinarDescription }}
							style={webinarDescStyle(isDefault)}
						></div>
						<div className="d-flex align-items-lg-center flex-column flex-md-row mt-4 pt-2">
							{btnCta1 && btnCta1.url && (
								<Button
									as={Link}
									href={btnCta1.url}
									size="lg"
									className={`px-4 py-2 me-md-4 mb-4 mb-md-0 inter-medium ${
										isDefault ? "btn-main" : "btn-blue"
									}`}
									target={btnCta1.target}
									variant={isDefault ? "secondary" : "primary"}
								>
									{btnCta1.title}
								</Button>
							)}
							{btnCta2 && btnCta2.url && (
								<Button
									as={Link}
									href={btnCta2.url}
									size="lg"
									className="px-4 py-2 inter-medium border-white btn-transparent"
									target={btnCta2.target}
								>
									{btnCta2.title}
								</Button>
							)}
						</div>
					</Col>
					<Col
						lg={4}
						className="mb-4 mb-lg-0 text-center text-lg-start mb-5 mb-lg-0"
					>
						{memberImage && (
							<GatsbyImage
								image={memberImage.localFile.childImageSharp.gatsbyImageData}
								className="rounded-circle object-fit-cover main-image border border-white"
								alt={memberImage.altText}
							/>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

const bannerWrapper = (bgImage) => ({
	marginTop: "-148px",
	padding: "240px 0 150px",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	minHeight: "660px",
	background: `linear-gradient(0deg, rgba(29, 18, 63, 0.55) 0%, rgba(29, 18, 63, 0.55) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
});

const webinarDescStyle = (isDefault) => ({
	backgroundColor: isDefault
		? "rgba(132, 121, 164, 0.40)"
		: "rgba(95, 68, 183, 0.10)",
	borderRadius: "12px",
});

export default HeroBanner;
