import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContactForm from "./contactForm";

const ContactUsSection = ({ heading, subHeading, tab1Label, tab2Label }) => {
	const [selectedTab, setSelectedTab] = useState("send-message");

	const handleTablClick = (tabName) => {
		setSelectedTab(tabName);
	};

	return (
		<section className="py-5 py-md-8" id="book-pathway-call">
			<Container>
				<Row className="justify-content-center">
					<Col lg={8} className="mb-4 mb-lg-0 text-center">
						<span
							className="text-uppercase inter-semibold text-primary"
							style={{ fontSize: "18px" }}
						>
							{subHeading}
						</span>
						<h2
							className="my-0 pt-3 text-primary inter-semibold"
							style={{ fontSize: "48px" }}
						>
							{heading}
						</h2>
					</Col>
				</Row>

				<Row className="mt-4 justify-content-center">
					<Col lg={8}>
						{/* <ul className="talk-tabs mx-auto border border-jason d-flex rounded-5 justify-content-center">
							<li
								onClick={() => handleTablClick("send-message")}
								className={`${
									selectedTab === "send-message" ? "active" : ""
								} d-flex justify-content-center align-items-center rounded-start-5`}
							>
								{tab1Label}
							</li>
							<li
								onClick={() => handleTablClick("book-call")}
								className={`${
									selectedTab === "book-call" ? "active" : ""
								} d-flex justify-content-center align-items-center rounded-end-5`}
							>
								{tab2Label}
							</li>
						</ul> */}
						<div className="">
							<div
								className={`${selectedTab === "send-message" ? "" : "d-none"}`}
							>
								<ContactForm />
							</div>

							{/* <div className={`${selectedTab === "book-call" ? "" : "d-none"}`}>
								<div id="blab-embed"></div>
								<script
									src="https://bookme.name/js/booklikeaboss.embed.js?i=22326&h=Qzm5SoVkTJmynJ4Z4YQgx2oAUochgIDfz4knJ5K8k71nlsgOxIilApHNNvAo"
									async
								></script>
							</div> */}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ContactUsSection;
