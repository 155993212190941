import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Masonry from "react-masonry-css";
import { GatsbyImage } from "gatsby-plugin-image";

const breakpointColumnsObj = {
	default: 3,
	991: 2,
	500: 1,
};

const ClientTestimonials = ({ testimonials, heading }) => {
	if (!testimonials) {
		return;
	}
	return (
		<section className="py-5 py-md-8 position-relative">
			<Container>
				<Row className={`justify-content-center text-center`}>
					<Col lg={7}>
						<h2 className="mb-0 text-light-black inter-semibold">{heading}</h2>
					</Col>
				</Row>
				<div className="mt-6">
					{testimonials.length > 0 && (
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className="testimonial-wrapper"
							columnClassName="testimonial-item"
						>
							{testimonials.map((item, i) => (
								<div key={i} className="p-4">
									<GatsbyImage
										image={
											item.testimonialsContent?.testimonialAuthorImage
												?.localFile.childImageSharp.gatsbyImageData
										}
										style={{ width: "100px", height: "100px" }}
										className="mx-auto rounded-circle mx-auto d-flex"
									/>
									<div
										className="text-dark-grey py-4 my-4 testimonial-item-content"
										dangerouslySetInnerHTML={{
											__html: item.testimonialsContent.testimonialContent,
										}}
									></div>
									<div>
										<h6 className="fs-6 inter-semibold text-primary mb-0">
											{item.title}
										</h6>
										<span
											style={{ fontSize: "14px" }}
											className="inter-regular text-dark-grey"
										>
											{item.testimonialsContent.testimonialAuthorPosition}
										</span>
									</div>
								</div>
							))}
						</Masonry>
					)}
				</div>
			</Container>
		</section>
	);
};

export default ClientTestimonials;
