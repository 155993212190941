import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import HeroBanner from "../components/hero";
import ClientTestimonials from "../components/client-testimonials";
import CtaBanner from "../components/cta-banner";
import WorkshopAbout from "../components/workshop-about";
import VideoCta from "../components/video-cta";
import ContactUsSection from "../components/contact-section";

const LandingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "landing" }) {
				homepage {
					homeBanner {
						bannerSubHeading
						bannerHeading
						bannerDescription
						bannerWebinarDescription
						bannerCta1 {
							title
							target
							url
						}
						bannerCta2 {
							title
							target
							url
						}
						bannerBackgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							sourceUrl
						}
						bannerMemberImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							sourceUrl
						}
					}
					testimonials {
						testimonialsSectionHeading
						selectTestimonials {
							... on WpTestimonial {
								testimonialsContent {
									testimonialAuthorPosition
									testimonialContent
									testimonialAuthorImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								title
							}
						}
					}
					aboutCta {
						aboutCtaSectionHeading
						aboutCta1 {
							title
							target
							url
						}
						aboutCta2 {
							title
							target
							url
						}
						aboutCtaBackgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						aboutCtaMeetHeading
						aboutCtaMeetContent
						aboutCtaMemberImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					stepsSection {
						stepsSectionHeading
						stepsSectionContent
						stepsSectionButton1 {
							title
							target
							url
						}
						stepsSectionButton2 {
							title
							target
							url
						}
						steps {
							stepHeading
							stepContent
							stepNumber
						}
					}
					videoCta {
						videoCtaHeading
						videoCtaSubHeading
						videoCta1 {
							title
							target
							url
						}
						videoCta2 {
							title
							target
							url
						}
						uploadVideo {
							altText
							mediaItemUrl
							sourceUrl
						}
					}
					contactAndBookingForms {
						contactSectionHeading
						contactSectionSubHeading
						contactFormTab2Label
						contactFormTab1Label
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						altText
						sourceUrl
						mediaDetails {
							height
							width
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, homepage },
	} = data;

	return (
		<>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(seoFields?.localBusinessSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.sourceUrl}`,
							width: `${seoFields?.image?.mediaDetails.width}`,
							height: `${seoFields?.image?.mediaDetails.height}`,
							alt: `${seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<div className="bold-fonts">
					<HeroBanner
						heading={homepage?.homeBanner.bannerHeading}
						subHeading={homepage?.homeBanner.bannerSubHeading}
						description={homepage?.homeBanner.bannerDescription}
						webinarDescription={homepage?.homeBanner.bannerWebinarDescription}
						btnCta1={homepage?.homeBanner.bannerCta1}
						btnCta2={homepage?.homeBanner.bannerCta2}
						bgImage={homepage?.homeBanner.bannerBackgroundImage?.sourceUrl}
						memberImage={homepage?.homeBanner.bannerMemberImage}
					/>
					<ClientTestimonials
						heading={homepage?.testimonials?.testimonialsSectionHeading}
						testimonials={homepage?.testimonials?.selectTestimonials}
					/>
					<CtaBanner
						heading={homepage?.aboutCta?.aboutCtaSectionHeading}
						btnCta1={homepage?.aboutCta?.aboutCta1}
						btnCta2={homepage?.aboutCta?.aboutCta2}
						bgImage={homepage?.aboutCta?.aboutCtaBackgroundImage}
						meetHeading={homepage?.aboutCta?.aboutCtaMeetHeading}
						meetContent={homepage?.aboutCta?.aboutCtaMeetContent}
						memberImage={homepage?.aboutCta?.aboutCtaMemberImage}
					/>
					<WorkshopAbout
						heading={homepage?.stepsSection?.stepsSectionHeading}
						content={homepage?.stepsSection?.stepsSectionContent}
						btnCta1={homepage?.stepsSection?.stepsSectionButton1}
						btnCta2={homepage?.stepsSection?.stepsSectionButton2}
						steps={homepage?.stepsSection?.steps}
					/>
					<VideoCta
						subHeading={homepage?.videoCta?.videoCtaSubHeading}
						heading={homepage?.videoCta?.videoCtaHeading}
						btnCta1={homepage?.videoCta?.videoCta1}
						btnCta2={homepage?.videoCta?.videoCta2}
						video={homepage?.videoCta?.uploadVideo}
					/>
					<ContactUsSection
						heading={homepage?.contactAndBookingForms?.contactSectionHeading}
						subHeading={
							homepage?.contactAndBookingForms?.contactSectionSubHeading
						}
						tab1Label={homepage?.contactAndBookingForms?.contactFormTab1Label}
						tab2Label={homepage?.contactAndBookingForms?.contactFormTab2Label}
					/>
				</div>
			</Layout>
		</>
	);
};

export default LandingPage;
