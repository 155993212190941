import React, { useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";
import { IoIosPlayCircle } from "react-icons/io";

const VideoCta = ({ heading, subHeading, btnCta1, btnCta2, video }) => {
	const [showPlayBtn, setShowPlayBtn] = useState(true);

	const vidRef = useRef(null);
	const handlePlayVideo = () => {
		vidRef.current.play();
		setShowPlayBtn(!showPlayBtn);
	};

	return (
		<section className="py-5 py-md-8 bg-schema-bg">
			<Container>
				<Row className="justify-content-center">
					<Col lg={8} className="mb-4 mb-lg-0 text-center">
						<span
							className="text-uppercase inter-semibold"
							style={{ fontSize: "18px" }}
						>
							{subHeading}
						</span>
						<h2
							className="my-0 pt-3 text-light-black inter-semibold"
							style={{ fontSize: "48px" }}
						>
							{heading}
						</h2>
					</Col>
				</Row>

				<Row className="mt-5">
					<Col lg={12}>
						<div className="position-relative">
							<video
								ref={vidRef}
								width="100%"
								controls
								height="auto"
								className="rounded-5 "
								style={{ maxHeight: "640px" }}
							>
								<source src={video.mediaItemUrl} type="video/mp4" />
							</video>

							{showPlayBtn && (
								<IoIosPlayCircle
									onClick={handlePlayVideo}
									className="position-absolute m-auto start-0 end-0 top-0 bottom-0"
									style={{ width: "80px", height: "80px", fill: "#4D4D4D" }}
								/>
							)}
						</div>
						{((btnCta1 && btnCta1.url) || (btnCta2 && btnCta2.url)) && (
							<div className="d-flex align-items-lg-center flex-column flex-md-row justify-content-md-center mt-5">
								{btnCta1 && btnCta1.url && (
									<Button
										as={Link}
										href={btnCta1.url}
										size="lg"
										className="px-4 py-2 me-md-4 mb-4 mb-md-0 inter-medium btn-blue"
										target={btnCta1.target}
										variant="primary"
									>
										{btnCta1.title}
									</Button>
								)}
								{btnCta2 && btnCta2.url && (
									<Button
										as={Link}
										href={btnCta2.url}
										size="lg"
										className="px-4 py-2 inter-medium btn-white"
										target={btnCta2.target}
									>
										{btnCta2.title}
									</Button>
								)}
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default VideoCta;
