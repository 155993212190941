import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";

const WorkshopAbout = ({ heading, content, btnCta1, btnCta2, steps }) => {
  return (
    <section className="py-5 py-md-8">
      <Container>
        <Row className="justify-content-lg-between">
          <Col
            lg={6}
            className="mb-4 mb-lg-0 text-start text-md-start pe-lg-4"
          >
            <h2
              className="text-primary inter-regular my-0 fs-2 lh-base"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            <div
              className="text-light-black my-4"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
            <div className="d-flex align-items-lg-center flex-column flex-md-row">
              {btnCta1 && btnCta1.url && (
                <Button
                  as={Link}
                  href={btnCta1.url}
                  size="lg"
                  className="px-4 py-2 me-md-4 mb-4 mb-md-0 inter-medium btn-blue"
                  target={btnCta1.target}
                  variant="primary"
                >
                  {btnCta1.title}
                </Button>
              )}
              {btnCta2 && btnCta2.url && (
                <Button
                  as={Link}
                  href={btnCta2.url}
                  size="lg"
                  className="px-4 py-2 inter-medium border-json btn-white"
                  target={btnCta2.target}
                >
                  {btnCta2.title}
                </Button>
              )}
            </div>
          </Col>
          <Col lg={4} className={`mb-4 mb-lg-0`}>
            <div>
              {steps.map((item, i) => (
                <div key={i} className="p-4 rounded-5 mb-4 about-item">
                  <span className="inter-semibold text-dark-grey">
                    {item.stepNumber}
                  </span>
                  <h3 className="m-0 pt-2 pb-3 inter-semibold text-primary">
                    {item.stepHeading}
                  </h3>

                  <div
                    className="m-0 fs-6 text-light-black"
                    dangerouslySetInnerHTML={{ __html: item.stepContent }}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WorkshopAbout;
